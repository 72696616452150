import { ButtonInCreation } from './reducer'
import { AnyAction, Dispatch } from 'redux'

export enum ButtonCreationActionTypes {
  SET_CREATE_BUTTON_DATA = 'SET_CREATE_BUTTON_DATA',
  UPDATE_BUTTON_DATA = 'UPDATE_BUTTON_DATA',
  CLEAR_CURRENT_BUTTON_DATA = 'CLEAR_CURRENT_BUTTON_DATA',
}

export const setButtonFormData = (integrationData: ButtonInCreation) => {
  return {
    type: ButtonCreationActionTypes.SET_CREATE_BUTTON_DATA,
    payload: integrationData,
  }
}
export const clearButtonData = () =>
  ({
    type: ButtonCreationActionTypes.CLEAR_CURRENT_BUTTON_DATA,
  } as unknown as AnyAction)
