import { Reducer } from 'redux'
import { links } from '../productsListing/reducer'
import { ReceivedResellerAuthorizationActionTypes } from './actions'
import {
  AgreementDurationType,
  ContractDurationType,
  DiscountType,
  ResellerAgreementStatus,
  Stakeholders,
} from '../../../admin/modules/resaleAuthorizations/reducer'
import {
  ChannelType,
  EulaType,
  Installments,
  OfferRecipients,
  PriceTypes,
} from '../privateOffer/reducer'
import { MetaData } from '../../../oppsync/modules/notification/reducer'
import { Dimension } from '../../../externalApps/modules/cppoResellerAgreement/reducer'

export interface ReceivedResellerAuthorization {
  id: string
  sellerName: string
  crmAwsResellerAgreementObjectId: string
  agreementDescription: string
  agreementName: string
  buyerAwsAccountId: string
  agreementStartDate?: string
  agreementExpirationDate: string
  emailCustomMessage: string
  contractDurationInMonthsForEndCustomer?: number
  agreementDurationType: AgreementDurationType
  discountType: DiscountType
  contractDurationType: ContractDurationType
  endCustomerEulaType: EulaType
  endCustomerEulaFiles: string[]
  resellerLicenseAgreementType: EulaType
  resellerLicenseAgreementFiles: string[]
  percentageDiscount?: number
  awsAgreementId: string
  agreementRecipients: OfferRecipients[]
  agreementStakeholders: Stakeholders[]
  installmentInfo: Installments[]
  metaData: MetaData[]
  renewal?: boolean
  renewalChannel?: ChannelType | null
  createdAt: string
  status: ResellerAgreementStatus
  resellerName: string
  draft?: boolean
  pricesArray?: [keyof PriceTypes]
  dimensions?: Dimension[]
  companyName?: string
}

export type ApiResponse = {
  pageNumber?: number
  pageSize?: number
  totalCount?: number
  sortBy?: string
  sortOrder?: string
  links?: links
}

export type ReceivedResellerAuthorizationState = {
  currentReceivedResellerAuthorizations: {
    [x: string]: ReceivedResellerAuthorization
  }
} & ApiResponse

const initialState: ReceivedResellerAuthorizationState = {
  currentReceivedResellerAuthorizations: {},
}

type actionType =
  | {
      type: ReceivedResellerAuthorizationActionTypes.SET_CURRENT_RECEIVED_RESELLER_AUTHORIZATION
      payload: ReceivedResellerAuthorization
    }
  | {
      type: ReceivedResellerAuthorizationActionTypes.CLEAR_CURRENT_RECEIVED_RESELLER_AUTHORIZATION
    }

export const reducer: Reducer<
  ReceivedResellerAuthorizationState,
  actionType
> = (state = initialState, action) => {
  switch (action.type) {
    case ReceivedResellerAuthorizationActionTypes.SET_CURRENT_RECEIVED_RESELLER_AUTHORIZATION: {
      return {
        ...state,
        currentReceivedResellerAuthorizations: {
          [action.payload.id]: action.payload as ReceivedResellerAuthorization,
        },
      }
    }
    case ReceivedResellerAuthorizationActionTypes.CLEAR_CURRENT_RECEIVED_RESELLER_AUTHORIZATION: {
      return {
        ...state,
        currentReceivedResellerAuthorizations: {},
      }
    }
    default:
      return state
  }
}
