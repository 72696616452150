import {
  saveReferral,
  fetchReferral,
  deleteReferral,
  fetchReferralSyncRecords,
  apiFetchLabraReferralStatusHistory,
} from '../../../../oppsync/api/CoSell'
import {
  ReferralFormSectionName,
  ReferralOppSections,
  PartialReferralObject,
  CustomerDetails,
  ProjectDetails,
  AwsDetails,
  LabraReferralStatusHistory,
  LabraReferralStatus,
  ReferralOppSectionFields,
} from './reducer'
import { AppDispatch, RootState } from '../../../../store'
import {
  PartnerCentralUsers,
  Referral,
  ReferralAPI,
  ReferralMetadata,
  ReferralObject,
} from '../reducer.ts'
import {
  startLoading,
  stopLoading,
} from '../../../../common/modules/loading/actions.ts'
import { LoadingTypes } from '../../../../common/modules/loading/reducer.ts'
import { errorLogger } from '../../../../common/utils/errorLogger.ts'
import { updateAppAlert } from '../../../../common/modules/appAlert/actions.ts'
import {
  RequestFailureMessage,
  cosellDefaultFieldsError,
} from '../../../../common/utils/messagesContants.ts'
import { getErrorMessages } from '../../../../common/utils/error.ts'
import { AxiosError, AxiosResponse } from 'axios'
import { autoSave } from '../../../../common/modules/autoSave/actions.ts'
import {
  APIReferralError,
  parseReferralAPIError,
} from '../../../../externalApps/api/errorHandlers.ts'
import {
  mapLeadApiToLead,
  mapReferralApiToReferral,
  removeEmptyValues,
} from '../../../../common/utils/CoSellHelpers/helperFunctions.ts'
import { isEmpty } from 'lodash'
import { camelize } from 'casing'
import { PartnerType } from '../../../../common/modules/partner/action.ts'
import { ReferralMetadataType, ReferralUpdatedBy } from '../types.ts'
import { ActionType } from '../../../../externalApps/modules/referrals/actions.ts'
import { ReferralStage, SyncRecord } from '../../../components/CoSell/types.ts'
import {
  PartialLeadObject,
  ReferralLeadSections,
} from '../LeadsReferralForm/types.ts'
import { CosellReferralType } from '../actions.ts'
import {
  mapLeadApiToForm,
  setLeadCreationData,
  setLeadDetails,
  setLeadFormFieldErrors,
  submitLeadForm,
} from '../LeadsReferralForm/actions.ts'
import { capitalizeFirstLetter } from '../../../../common/utils/helperFunctions.ts'
import { FormikErrors } from 'formik'
import { getReferredBy } from '../../../../externalApps/modules/customForm/actions.ts'
//import { ProjectDetails } from '../../../components/CoSell/ReferralForm/ProjectDetailsForm/ProjectDetails/ProjectDetails.tsx'
export enum ReferralFormActions {
  SET_REFERRAL_FORM = 'SET_REFERRAL_FORM',
  UPDATE_REFERRAL_FORM = 'UPDATE_REFERRAL_FORM',
  SET_REFERRAL = 'SET_REFERRAL',
  SET_FORM_REFERRAL_ERRORS = 'SET_FORM_REFERRAL_ERRORS',
  CLEAR_REFERRAL_FORM = 'CLEAR_REFERRAL_FORM',
  SET_LEAD_FORM = 'SET_LEAD_FORM',
  SET_LEAD = 'SET_LEAD',
  SET_NEXT_STEP_INPUT_MESSAGE = 'SET_NEXT_STEP_INPUT_MESSAGE',
  SET_LABRA_REFERRAL_STATUS_HISTORY = 'SET_LABRA_REFERRAL_STATUS_HISTORY',
  ADD_LABRA_REFERRAL_STATUS_HISTORY = 'ADD_LABRA_REFERRAL_STATUS_HISTORY',
}

export const setReferralCreationData = (
  onboardingFormData: Partial<ReferralOppSections>
) => ({
  type: ReferralFormActions.SET_REFERRAL_FORM,
  payload: onboardingFormData,
})

export const setNextStepInputMessage = (message: string) => ({
  type: ReferralFormActions.SET_NEXT_STEP_INPUT_MESSAGE,
  payload: message,
})

export const clearReferralForm = () => ({
  type: ReferralFormActions.CLEAR_REFERRAL_FORM,
})

export type ReferralChangeType = {
  sectionName: ReferralFormSectionName
  data: Partial<CustomerDetails> | Partial<ProjectDetails> | Partial<AwsDetails>
}

export const setReferralDetails = (referral: PartialReferralObject) => ({
  type: ReferralFormActions.SET_REFERRAL,
  payload: referral,
})

export const updateReferralCreationData = (data: ReferralChangeType) => ({
  type: ReferralFormActions.UPDATE_REFERRAL_FORM,
  payload: data,
})

export const emptyReferralFormFieldErrors = {} as Record<keyof Referral, string>

export const setFormFieldErrors = (errors: Record<keyof Referral, string>) => ({
  type: ReferralFormActions.SET_FORM_REFERRAL_ERRORS,
  payload: errors,
})

export const addLabraReferralStatusHistory = (
  status: LabraReferralStatus,
  now = new Date().toISOString()
) => ({
  type: ReferralFormActions.ADD_LABRA_REFERRAL_STATUS_HISTORY,
  payload: {
    status,
    updated_at: now,
  },
})

export const submitReferralFormForAutosave =
  (
    referral: Partial<ReferralOppSections>,
    status: ReferralMetadata['referralStatus'] = 'draft',
    referralId?: string
  ) =>
    async (dispatch: AppDispatch, getState: () => RootState) => {
      try {
        dispatch(
          autoSave('coSellReferralForm', () =>
            dispatch(_submitReferralForm(referral, status, referralId))
          )
        )
      } catch (e: any) {
        const error = e as AxiosError<APIReferralError>

        const globalState = getState()
        errorLogger({ globalState })(error as Error)
      }
    }

export const submitReferralForm =
  (
    referral: Partial<ReferralOppSections>,
    status: ReferralMetadata['referralStatus'] = 'draft',
    referralId?: string,
    actionType?: ActionType,
    referralType: CosellReferralType = 'opportunity'
  ) =>
    async (dispatch: AppDispatch, getState: () => RootState) => {
      dispatch(startLoading(LoadingTypes.COSELL))
      const submitFn =
        referralType === 'opportunity' ? _submitReferralForm : submitLeadForm
      const setErrorsAction =
        referralType === 'opportunity'
          ? setFormFieldErrors
          : setLeadFormFieldErrors
      const mapApiToRef =
        referralType === 'opportunity'
          ? mapReferralApiToReferral
          : mapLeadApiToLead
      try {
        const result = await dispatch(
          submitFn(referral, status, referralId, actionType)
        )
        // eslint-disable-next-line @typescript-eslint/no-explicit-any

        if (result && result.error) throw result.error
        dispatch(
          updateAppAlert({
            message:
              status === 'draft'
                ? 'Referral was successfully saved as draft.'
                : 'Referral was successfully submitted.',
            messageType: 'SUCCESS',
            autoClose: true,
          })
        )
        dispatch(setNextStepInputMessage(''))
      } catch (e: any) {
        const error = e as AxiosError<APIReferralError>
        const errorResponse = error.response?.data

        if (errorResponse) {
          try {
            const parsedErrors = parseReferralAPIError(errorResponse)
            const parsedErrorsTransformed = mapApiToRef(parsedErrors)
            if (!isEmpty(parsedErrorsTransformed)) {
              dispatch(setErrorsAction(parsedErrorsTransformed as any)) // forcing any here to comply with Referral & ReferralLead type, but this could be handled by moving this error handling function outside and having 2 branches with different types, like with generics
              dispatch(
                updateAppAlert({
                  message: cosellDefaultFieldsError,
                  messageType: 'ERROR',
                  autoClose: false,
                })
              )
            } else {
              dispatch(
                updateAppAlert({
                  message: getErrorMessages([RequestFailureMessage])(
                    e.response as AxiosResponse<ErrorResponse>
                  ),
                  messageType: 'ERROR',
                  autoClose: false,
                })
              )
            }
          } catch (e) {
            console.error('Error while parsing the response payload', e)
            const globalState = getState()
            errorLogger({ globalState })(error as Error)
          }
        } else {
          dispatch(
            updateAppAlert({
              message: getErrorMessages([RequestFailureMessage])(
                e.response as AxiosResponse<ErrorResponse>
              ),
              messageType: 'ERROR',
              autoClose: false,
            })
          )
        }

        const globalState = getState()
        errorLogger({ globalState })(error as Error)
      } finally {
        dispatch(stopLoading(LoadingTypes.COSELL))
      }
    }

const _submitReferralForm =
  (
    referral: Partial<ReferralOppSections>,
    status: ReferralMetadata['referralStatus'] = 'draft',
    referralId?: string,
    actionType?: ActionType
  ) =>
    async (dispatch: AppDispatch, getState: () => RootState) => {
      const partnerData = getState().PartnerData.user.partnerData
      const stage =
        getState().referralFormCreation.referralForm.projectDetails.stage

      const referralMetadataReferralFormCreation =
        getState().referralFormCreation.referral?.referralMetadata

      const referralMetadataCustomForm =
        getState().customForm.hubspotForm.customData?.referralMetadata
      // const partnerCentralUsersEmailCosell =
      //  getState().cosell.oppsync.partnerCentralUsers || []
      const partnerCentralUsersEmail =
        getState().customForm.hubspotForm.customData?.partnerCentralUsers || []
      const referralFromBackend =
        getState().referralFormCreation.referral?.referralObject
      const { acceptanceStatus, rejectionReason, nextStep, nextStepHistory } =
        getState().referralFormCreation.referralForm.projectDetails
      const objectCrmId =
        getState().referralFormCreation.referral?.referralObject?.Id
      const referredBy =
        getState().referralFormCreation.referral?.referralObjectTransformed
          ?.referredBy
      const extraData = {
        stage,
        acceptanceStatus,
        rejectionReason,
        nextStep,
        nextStepHistory,
      }
      const dataTransformToApiFormat = referralFormToAPI(
        {
          ...referral,
          extra: extraData,
        },
        objectCrmId
      )

      const dataWithoutEmptyValues = removeEmptyValues(dataTransformToApiFormat)

      const partnerUserSelected = partnerCentralUsersEmail.filter(
        p =>
          p.email ===
          referral[ReferralFormSectionName.PROJECT_DETAILS]?.opportunityOwnerEmail
      )[0]

      const partnerCentralUserName = `${capitalizeFirstLetter(
        partnerUserSelected?.first_name
      )} ${capitalizeFirstLetter(partnerUserSelected?.last_name)}`

      const referralFromForm = dataWithoutEmptyValues
      const referralPayload: Partial<ReferralAPI> = {
        ...referralFromBackend,
        ...Object.fromEntries(
          Object.entries(referralFromForm).filter(
            ([key, value]) => value !== '' && value !== undefined
          )
        ),
        Labra__Referred_By__c: referredBy || 'Partner Referral',
        Labra__Last_Updated_By__c: ReferralUpdatedBy.LABRA_CRM,
        Labra__AWS_Partner_Central_User_Name__c: partnerCentralUserName,
      }

      const referralMetadata =
        referralMetadataReferralFormCreation ||
        camelize(referralMetadataCustomForm)
      const partnerDataFromCrm =
        getState().customForm.hubspotForm.customData?.labraSettingObject
      const saveReferralParams = {
        referralObject: referralPayload,
        invokeSync: status === 'submitted',
        referralMetadata: {
          ...referralMetadata,
          crmId: referralMetadata?.crmId || partnerData?.crmId || '',
          referralType: 'referral_opportunity' as ReferralMetadataType,
          referralStatus: status,
          crmParentTable: 'labra_referral',
          crmPrimaryKeyName: 'id',
        },
        labraSettingObject: {
          consultingPartner: false,
          technologyPartner: false,
          isv: (partnerData?.isIsv || partnerDataFromCrm?.isv) ?? false,
          srrp: (partnerData?.isSrrp || partnerDataFromCrm?.srrp) ?? false,
          accelerateProgram:
            (partnerData?.accelerateProgram ||
              partnerDataFromCrm?.accelerate_program) ??
            false,
          awsFieldsVersion:
            partnerData?.awsFieldsVersion ||
            partnerDataFromCrm?.aws_fields_version ||
            'v14',
        },
      }

      try {
        const { data } = await saveReferral(
          saveReferralParams,
          referralId,
          actionType
        )

        const { referral_id: newReferralId, object_crm_id: objectCrmId } = data
        const lastReferralId = referralId ? referralId : newReferralId
        // save status history beforehand to prevent race condition
        // where component will try to fetch the data right after the
        // setReferralDetails
        await dispatch(
          addLabraReferralStatusHistory(
            status === 'submitted'
              ? LabraReferralStatus.SUBMITTED
              : LabraReferralStatus.DRAFT
          )
        )
        await dispatch(
          setReferralDetails({
            referralMetadata: {
              ...saveReferralParams.referralMetadata,
              ...(!isEmpty(lastReferralId) && { referralId: newReferralId }),
              ...(!isEmpty(objectCrmId) && {
                crmPrimaryKeyValue: objectCrmId,
              }),
              createdAt:
                saveReferralParams.referralMetadata.createdAt ||
                new Date().toISOString(),
            },
            referralObject: {
              ...referralPayload,
              ...(!isEmpty(lastReferralId) && { Id: objectCrmId }),
            },
          })
        )
      } catch (e: any) {
        const error = e as AxiosError<APIReferralError>

        const globalState = getState()
        errorLogger({ globalState })(error as Error)
        // intentionally returning error because redux does not bubble up the error
        return { error: e }
      }
    }

const referralFormToAPI = (
  values: Partial<ReferralOppSections>,
  objectCrmId?: string
): Partial<ReferralAPI> => {
  const {
    customerDetails,
    contacts,
    projectDetails,
    awsDetails,
    saasDocumentation,
    awsContactsDetails,
    extra,
  } = values

  return {
    Id: objectCrmId,
    Name: projectDetails?.partnerProjectTitle,
    Labra__IsOppFromMarketingActivity__c: projectDetails?.isThisFromMarketing,
    Labra__Labra_ACE_CRM_Lead_UniqueIdentifier__c: '',
    Labra__apnReviewerComments__c: '',
    Labra__Sync_to_AWS__c: true,
    Labra__awsSFCampaignName__c: projectDetails?.marketingCampaignCode,
    Labra__Offer_ID__c: awsDetails?.offerId,
    Labra__Other_Solution_Offered__c: projectDetails?.otherSolutionOffered,
    Labra__Parent_Opportunity_ID__c: projectDetails?.parentOpportunityId,
    Labra__Partner_Contact_Title__c: contacts?.partnerSalesContactTitle,
    Labra__AWS_Recommended_Action__c: awsDetails?.recommendedNextBestActions,
    Labra__AWS_Marketplace_Engagement_Score__c: awsDetails?.engagementScore,
    Labra__APN_CRM_Unique_Identifier__c: '',
    Labra__AWS_Account_Id__c: awsDetails?.awsAccountId,
    Labra__Campaign_Name__c: awsDetails?.apnProgram?.join(';'),
    Labra__AWS_Account_Owner_Email__c:
      awsContactsDetails?.awsAccountManagerEmail,
    Labra__AWS_Account_Owner_Name__c: awsContactsDetails?.awsAccountManagerName,
    Labra__AWS_Internal_Stage__c: '',
    Labra__AWS_Partner_Central_User_Email__c:
      projectDetails?.opportunityOwnerEmail,
    Labra__AWS_Partner_Central_User_Name__c:
      projectDetails?.opportunityOwnerName,
    Labra__AWS_Sales_Rep_Email__c: awsContactsDetails?.awsSalesRepEmail,
    Labra__AWS_Sales_Rep_Name__c: awsContactsDetails?.awsSalesRepName,
    Labra__City__c: customerDetails?.city,
    Labra__Company_Name__c: customerDetails?.companyName,
    Labra__Country__c: customerDetails?.country,
    Labra__Primary_Sales_Rep_Email__c: contacts?.partnerSalesContactEmail,
    Labra__Primary_Sales_Rep_First_Name__c: contacts?.partnerContactFirstName,
    Labra__Primary_Sales_Rep_Last_Name__c: contacts?.partnerContactLastName,
    Labra__Primary_Sales_Rep_Phone__c: contacts?.partnerSalesContactPhone,
    Labra__Delivery_Model__c: projectDetails?.deliveryModel?.join(';'),
    Labra__Description__c: projectDetails?.customerBusinessProblem,
    Labra__Expected_Monthly_AWS_Revenue__c:
      projectDetails?.expectedMonthlyAwsRevenue,
    Labra__ISV_Manager_Email__c: awsContactsDetails?.awsIsvSuccessManagerEmail,
    Labra__ISV_Manager_Name__c: awsContactsDetails?.awsIsvSuccessManagerName,
    Labra__Industry__c: customerDetails?.industry,
    Labra__Next_Step_History__c: extra?.nextStepHistory,
    Labra__Next_step__c: extra?.nextStep,
    Labra__Competitive_Tracking__c: awsDetails?.competitiveTracking,
    Labra__Other_Competitive_Tracking__c: awsDetails?.competitiveTrackingOther,
    Labra__Other_Industry__c: customerDetails?.otherIndustry,
    Labra__Other_PDM_Email__c: awsContactsDetails?.wwpsPdmEmail,
    Labra__Other_PDM__c: awsContactsDetails?.wwpsPdm,
    Labra__PDM_Email__c: awsContactsDetails?.pdmEmail,
    Labra__PDM__c: awsContactsDetails?.pdmName,
    Labra__Postal_Code__c: customerDetails?.postalCode
      ? customerDetails.postalCode.toString()
      : '',
    Labra__Primary_Need_From_AWS_MultiSelect__c:
      projectDetails?.partnerPrimaryNeedFromAws?.join(';'),

    Labra__State__c: customerDetails?.state,
    Labra__Street_Address__c: customerDetails?.streetAddress,
    Labra__Success_Manager_Email__c: awsContactsDetails?.psmEmail,
    Labra__Success_Manager_Name__c: awsContactsDetails?.psmName,
    Labra__Use_Case__c: projectDetails?.useCase,
    Labra__Website__c: customerDetails?.website,
    Labra__Target_Close_Date__c: projectDetails?.targetCloseDate,
    Labra__Solution_offered__c: projectDetails?.solutionOffered?.join(';'),
    Labra__Opportunity_Type__c: projectDetails?.opportunityType,
    Labra__Sales_Activities__c: projectDetails?.salesActivities?.join(';'),
    Labra__Customer_DUNS__c: customerDetails?.duns,
    Labra__AWS_products__c: awsDetails?.awsProducts?.join(';'),
    Labra__Referral_Stage__c: extra?.stage || '',
    Labra__Rejection_reason__c: extra?.rejectionReason || '',
    Labra__Acceptance_Status__c: extra?.acceptanceStatus || 'Accepted',
    Labra__AWS_Closed_Lost_Reason__c: awsDetails?.closedReason,
    Labra__AWS_Closed_Lost_Reason_Code__c: '',
    Labra__Has_classified_NatSec_Information__c:
      customerDetails?.hasClassifiedNationalSecurityInformation,
    Labra__Additional_Comments__c: projectDetails?.additionalComments,
    Labra__AWS_Close_Date__c: awsDetails?.awsCloseDate,
    Labra__contractStartDate__c: saasDocumentation?.contractEffectiveDate,
    Labra__contractEndDate__c: saasDocumentation?.contractExpirationDate,
    Labra__customerSoftwareValueCurrency__c: saasDocumentation?.currency,
    Labra__customerSoftwareValue__c:
      saasDocumentation?.customerSoftwareValue || null,
    Labra__procurementType__c: saasDocumentation?.procurementType,
    Labra__Customer_Email__c: customerDetails?.email,
    Labra__Customer_First_Name__c: customerDetails?.firstName,
    Labra__Customer_Last_Name__c: customerDetails?.lastName,
    Labra__Customer_Phone__c: customerDetails?.phone,
    Labra__Customer_Title__c: customerDetails?.title,
    Labra__marketingActivityChannel__c:
      projectDetails?.marketingActivityChannel === null
        ? null
        : projectDetails?.marketingActivityChannel?.join(';'),
    Labra__marketingActivityUsecase__c:
      projectDetails?.marketingActivityUseCase === null
        ? null
        : projectDetails?.marketingActivityUseCase?.join(';'),
    Labra__MDF_Needed__c: projectDetails?.marketingFundsUsed,
    Labra__Referred_By__c: projectDetails?.referredBy,
  }
}
export const referralAPIToForm = (
  values: Partial<ReferralAPI>
): RecursivePartial<ReferralOppSections> => {
  return {
    customerDetails: {
      companyName: values.Labra__Company_Name__c,
      country: values.Labra__Country__c,
      industry: values.Labra__Industry__c,
      otherIndustry: values.Labra__Other_Industry__c,
      website: values.Labra__Website__c,
      city: values.Labra__City__c,
      duns: values.Labra__Customer_DUNS__c,
      postalCode: values.Labra__Postal_Code__c,
      state: values.Labra__State__c,
      streetAddress: values.Labra__Street_Address__c,
      firstName: values.Labra__Customer_First_Name__c,
      lastName: values.Labra__Customer_Last_Name__c,
      email: values.Labra__Customer_Email__c,
      phone: values.Labra__Customer_Phone__c,
      title: values.Labra__Customer_Title__c,
      hasClassifiedNationalSecurityInformation:
        values.Labra__Has_classified_NatSec_Information__c,
    },
    projectDetails: {
      customerBusinessProblem: values.Labra__Description__c,
      deliveryModel: values.Labra__Delivery_Model__c?.split(';'),
      isThisFromMarketing: values.Labra__IsOppFromMarketingActivity__c,
      marketingFundsUsed: values.Labra__MDF_Needed__c,
      parentOpportunityId: values.Labra__Parent_Opportunity_ID__c,
      opportunityOwnerEmail: values.Labra__AWS_Partner_Central_User_Email__c,
      opportunityOwnerName: values.Labra__AWS_Partner_Central_User_Name__c,
      opportunityType: values.Labra__Opportunity_Type__c,
      partnerPrimaryNeedFromAws:
        values.Labra__Primary_Need_From_AWS_MultiSelect__c
          ? values.Labra__Primary_Need_From_AWS_MultiSelect__c.split(';')
          : [],
      partnerProjectTitle: values.Name,
      salesActivities: values.Labra__Sales_Activities__c
        ? values.Labra__Sales_Activities__c.split(';')
        : [],
      solutionOffered: values.Labra__Solution_offered__c
        ? values.Labra__Solution_offered__c.split(';')
        : [],
      otherSolutionOffered: values.Labra__Other_Solution_Offered__c,
      targetCloseDate: values.Labra__Target_Close_Date__c,
      useCase: values.Labra__Use_Case__c,
      expectedMonthlyAwsRevenue: values.Labra__Expected_Monthly_AWS_Revenue__c,
      marketingActivityChannel:
        values.Labra__marketingActivityChannel__c?.split(';'),
      marketingActivityUseCase:
        values.Labra__marketingActivityUsecase__c?.split(';'),
      marketingCampaignCode: values.Labra__awsSFCampaignName__c,
      referredBy: values.Labra__Referred_By__c,
      additionalComments: values.Labra__Additional_Comments__c,
    },
    contacts: {
      partnerContactFirstName: values.Labra__Primary_Sales_Rep_First_Name__c,
      partnerContactLastName: values.Labra__Primary_Sales_Rep_Last_Name__c,
      partnerSalesContactEmail: values.Labra__Primary_Sales_Rep_Email__c,
      partnerSalesContactPhone: values.Labra__Primary_Sales_Rep_Phone__c,
      partnerSalesContactTitle: values.Labra__Partner_Contact_Title__c,
    },
    awsDetails: {
      apnProgram: values.Labra__Campaign_Name__c
        ? values.Labra__Campaign_Name__c.split(';')
        : [],
      awsProducts: values.Labra__AWS_products__c
        ? values.Labra__AWS_products__c.split(';')
        : [],
      awsAccountId: values.Labra__AWS_Account_Id__c,
      offerId: values.Labra__Offer_ID__c,
      competitiveTracking: values.Labra__Competitive_Tracking__c,
      competitiveTrackingOther: values.Labra__Other_Competitive_Tracking__c,
      closedReason: values.Labra__AWS_Closed_Lost_Reason__c,
      awsClosedReason: values.Labra__AWS_Closed_Lost_Reason_Code__c,
      awsCloseDate: values.Labra__AWS_Close_Date__c,
      engagementScore: values.Labra__AWS_Marketplace_Engagement_Score__c,
      recommendedNextBestActions: values.Labra__AWS_Recommended_Action__c,
      customerSegment: values?.Labra__Customer_Segment__c,
      isGreenfield: values?.Labra__isGreenfield__c,
    },
    saasDocumentation: {
      contractEffectiveDate: values.Labra__contractStartDate__c,
      contractExpirationDate: values.Labra__contractEndDate__c,
      currency: values.Labra__customerSoftwareValueCurrency__c,
      customerSoftwareValue: values.Labra__customerSoftwareValue__c,
      procurementType: values.Labra__procurementType__c,
    },
    awsContactsDetails: {
      awsAccountManagerEmail: values.Labra__AWS_Account_Owner_Email__c,
      awsAccountManagerName: values.Labra__AWS_Account_Owner_Name__c,
      awsSalesRepEmail: values.Labra__AWS_Sales_Rep_Email__c,
      awsSalesRepName: values.Labra__AWS_Sales_Rep_Name__c,
      awsIsvSuccessManagerEmail: values.Labra__ISV_Manager_Email__c,
      awsIsvSuccessManagerName: values.Labra__ISV_Manager_Name__c,
      psmEmail: values.Labra__Success_Manager_Email__c,
      psmName: values.Labra__Success_Manager_Name__c,
      pdmEmail: values.Labra__PDM_Email__c,
      pdmName: values.Labra__PDM__c,
      wwpsPdmEmail: values.Labra__Other_PDM_Email__c,
      wwpsPdm: values.Labra__Other_PDM__c,
    },
    extra: {
      stage: values.Labra__Referral_Stage__c as ReferralStage,
      acceptanceStatus: values.Labra__Acceptance_Status__c,
      rejectionReason: values.Labra__Rejection_reason__c,
      nextStep: values.Labra__Next_step__c,
      nextStepHistory: values.Labra__Next_Step_History__c,
    },
  }
}

export const formikErrorsToReferral = (
  errors: FormikErrors<ReferralOppSections>
): Record<string, string> => {
  let buffer = {} as Record<string, string>
  for (const key in errors) {
    const nestedErrors = errors[key as keyof ReferralOppSections] as
      | Record<string, string>
      | undefined
    if (nestedErrors) {
      for (const subKey in nestedErrors) {
        buffer[subKey as keyof Referral] = nestedErrors[subKey]
      }
    }
  }
  return buffer
}

const getReferralType = (referral: any): CosellReferralType => {
  if (referral?.referral_metadata?.referral_type === 'referral_lead') {
    return 'lead'
  }
  return 'opportunity'
}

interface ReferralActions {
  setReferralDetails: typeof setReferralDetails | typeof setLeadDetails
  setReferralCreationData:
  | typeof setReferralCreationData
  | typeof setLeadCreationData
  transform: typeof referralAPIToForm | typeof mapLeadApiToForm
}

export const getReferralActions = (
  referralType: CosellReferralType
): ReferralActions => {
  switch (referralType) {
    case 'lead':
      return {
        setReferralDetails: setLeadDetails,
        setReferralCreationData: setLeadCreationData,
        transform: mapLeadApiToForm,
      }
    case 'opportunity':
      return {
        setReferralDetails: setReferralDetails,
        setReferralCreationData: setReferralCreationData,
        transform: referralAPIToForm,
      }
  }
}

export const getReferral =
  (referralId: string, partnerType: PartnerType) =>
    async (dispatch: AppDispatch, getState: () => RootState) => {
      dispatch(startLoading(LoadingTypes.COSELL_FETCH_REFERRAL))
      const partnerId =
        getState().PartnerData[partnerType].partnerData?.partnerId || ''
      try {
        const { data: referralData } = await fetchReferral(referralId)
        const referralType = getReferralType(referralData)
        const actions = getReferralActions(referralType)

        const referralObject = {
          ...referralData.referral_object,
          Labra__Referred_By__c: getReferredBy(
            referralData.referral_object.Labra__Referred_By__c
          ),
        }
        await dispatch(
          actions.setReferralCreationData(
            actions.transform(referralObject as any) as any
          )
        )
        const { data: referralSyncData } = await fetchReferralSyncRecords(
          referralId
        )
        await dispatch(
          actions.setReferralDetails({
            referralMetadata: {
              ...camelize(referralData.referral_metadata),
            },
            referralObject: referralObject,
            referralSyncs: camelize(referralSyncData) as any,
          })
        )
      } catch (e: any) {
        const error = e as AxiosError<APIReferralError>
        dispatch(
          updateAppAlert({
            message: getErrorMessages([RequestFailureMessage])(
              e.response as AxiosResponse<ErrorResponse>
            ),
            messageType: 'ERROR',
            autoClose: false,
          })
        )
        const globalState = getState()
        errorLogger({ globalState })(error as Error)
      } finally {
        dispatch(stopLoading(LoadingTypes.COSELL_FETCH_REFERRAL))
      }
    }

export const deleteDraftReferral =
  (referralId: string) =>
    async (dispatch: AppDispatch, getState: () => RootState) => {
      dispatch(startLoading(LoadingTypes.COSELL_DELETE_REFERRAL))

      try {
        await deleteReferral(referralId)

        return {
          sucess: true,
        }
      } catch (e: any) {
        const error = e as AxiosError<APIReferralError>
        dispatch(
          updateAppAlert({
            message: getErrorMessages([RequestFailureMessage])(
              e.response as AxiosResponse<ErrorResponse>
            ),
            messageType: 'ERROR',
            autoClose: false,
          })
        )
        const globalState = getState()
        errorLogger({ globalState })(error as Error)
        return {
          sucess: false,
          error: error,
        }
      } finally {
        dispatch(stopLoading(LoadingTypes.COSELL_DELETE_REFERRAL))
      }
    }

export const setLabraReferralStatusHistory = (
  statusHistory: LabraReferralStatusHistory[]
) => ({
  type: ReferralFormActions.SET_LABRA_REFERRAL_STATUS_HISTORY,
  payload: statusHistory,
})

export const fetchLabraReferralStatusHistory =
  (partnerId: string, referralId: string, externalApi: boolean = false) =>
    async (dispatch: AppDispatch, getState: () => RootState) => {
      dispatch(
        startLoading(LoadingTypes.COSELL_FETCH_LABRA_REFERRAL_STATUS_HISTORY)
      )

      try {
        const {
          data: { status_history },
        } = await apiFetchLabraReferralStatusHistory(
          partnerId,
          referralId,
          externalApi
        )

        dispatch(setLabraReferralStatusHistory(status_history))
      } catch (e: any) {
        dispatch(setLabraReferralStatusHistory([]))
        const error = e as AxiosError<APIReferralError>
        dispatch(
          updateAppAlert({
            message: getErrorMessages([RequestFailureMessage])(
              e.response as AxiosResponse<ErrorResponse>
            ),
            messageType: 'ERROR',
            autoClose: false,
          })
        )
        const globalState = getState()
        errorLogger({ globalState })(error as Error)
      } finally {
        dispatch(
          stopLoading(LoadingTypes.COSELL_FETCH_LABRA_REFERRAL_STATUS_HISTORY)
        )
      }
    }

export const setValidationErrors =
  (
    errors: FormikErrors<ReferralOppSections>,
    referralType: CosellReferralType
  ) =>
    (dispatch: AppDispatch) => {
      const setErrorsAction =
        referralType === 'opportunity'
          ? setFormFieldErrors
          : setLeadFormFieldErrors
      const referralErrors = formikErrorsToReferral(errors)
      dispatch(setErrorsAction(referralErrors as any))
    }
