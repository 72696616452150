import { AnyAction, Reducer } from 'redux'
import { COSELL_ACTIONS, ReferralFormField } from './actions'
import { SyncObjectType } from '../salesPipeline/syncObject/reducer'
import { ReferralMetadataType, ReferralUpdatedBy } from './types'
import { SelectorOptions } from '@labrav/react-components/lib/@types/types/forms'
import {
  LeadStage,
  ReferralStage,
  ReferralStatus,
} from '../../components/CoSell/types'

export type SortDirection = 'asc' | 'desc'

export type ReferralReferredBy = 'AWS Referral' | 'Partner Referral'
export type Referral = {
  companyName: string
  id: string
  partnerContactFirstName: string
  partnerContactLastName: string
  stage: ReferralStage
  targetCloseDate: string
  createdDate: string
  lastModifiedDate: string
  lastUpdated: string
  lastUpdatedBy: string
  labraOffer: string
  cloudProviderApprovalStatus: string
  cloudProviderReferralID: string
  referralOwner: string
  lastSyncStatus: ReferralSyncStatus
  acceptByDate: string
  cloudProviderAcceptanceStatus: ReferralStatus
  customerFirstName: string
  customerLastName: string
  acceptanceStatus?: ReferralStatus
  opportunityOwnerName: string
  useCase: string
  country: string
  deliveryModel: string
  expectedMonthlyAwsRevenue?: number
  industry: string
  isThisFromMarketing: string
  postalCode: string
  partnerPrimaryNeedFromAws: string
  website: string
  partnerProjectTitle: string
  city: string
  state: string
  customerBusinessProblem: string
  solutionOffered: string
  customerTitle: string
  customerPhone: string
  customerEmail: string
  opportunityType: string
  salesActivities: string
  opportunityOwnerEmail: string
  crmUniqueIdentifier?: string
  leadStatus?: LeadStage
  referredBy: ReferralReferredBy
  nextStepHistory: string
  nextStep?: string
  internalStage: string
  awsAccountId: string
  awsAccountOwnerEmail: string
  awsAccountOwnerName: string
  awsSalesRepEmail: string
  awsSalesRepName: string
  partnerSalesContactEmail: string
  partnerSalesContactPhone: string
  awsIsvSuccessManagerEmail: string
  awsIsvSuccessManagerName: string
  competitiveTracking: string
  wwpsPdmEmail: string
  wwpsPdm: string
  pdmName: string
  pdmEmail: string
  streetAddress: string
  psmEmail: string
  psmName: string
  duns: string
  awsProducts: string
  apnProgram?: string
  rejectionReason: string
  closedReason: string
  awsClosedReason: string
  hasClassifiedNationalSecurityInformation: string
  additionalComments: string
  awsCloseDate: string
  contractEffectiveDate: string
  contractExpirationDate: string
  currency: string
  customerSoftwareValue: string
  procurementType: string
  marketingActivityChannel: string
  marketingActivityUseCase: string
  engagementScore: string
  campaignName?: string
  otherIndustry: string
  otherCompetitiveTracking: string
  syncToAws: boolean
  marketingCampaignCode: string
  aceId: string
  reviewerComments: string
  recommendedNextBestActions: string
  otherSolutionOffered: string
  parentOpportunityId: string
  partnerSalesContactTitle: string
  marketingFundsUsed: string
  customerSegment?: string
  isGreenfield?: string
}

export type ReferralMetrics = {
  incomingLeads: number
  incomingReferral: number
  outgoingReferral: number
}
export type ReferralSyncStatus =
  | 'success'
  | 'error'
  | 'Failed'
  | 'in_progress'
  | 'warning'
export type ReferralSync = {
  status: ReferralSyncStatus
  error?: string
  createdAt: string
  updatedAt?: string
}

export type ReferralSubmitStatus = 'submitted' | 'draft'

export type ReferralMetadata = {
  crmId: string
  crmParentTable: string
  crmPrimaryKeyName: string
  crmPrimaryKeyValue: string
  referralId: string
  partnerId?: string
  referralStatus: ReferralSubmitStatus
  referralType: ReferralMetadataType
  lastSyncStatus: ReferralSyncStatus
  createdAt: string
  updatedAt: string
}
export type ReferralSyncs = {
  syncs: SyncObjectType[]
}
export type ReferralObject = {
  referralMetadata: ReferralMetadata
  referralObject: Referral
}
export type CoSellState = {
  referralMetrics: ReferralMetrics
  referralObjects: {
    referrals: ReferralObject[]
    count?: number
    pageNumber?: number
    pageSize?: number
    hasMore: boolean
  } | null
  awsProducts: SelectorOptions[]
  solutionOfferedOptions: SelectorOptions[]
  referralDetails?: ReferralObject
  partnerCentralUsers: PartnerCentralUsers[]
  isShowRequiredFieldsOnly: boolean
  categoriesWithNoFields?: string[]
  referralFormFields: ReferralFormField[]
}

export const initialState = {
  referralMetrics: {
    incomingLeads: 0,
    incomingReferral: 0,
    outgoingReferral: 0,
  },
  referralObjects: null,
  awsProducts: [],
  solutionOfferedOptions: [],
  partnerCentralUsers: [],
  isShowRequiredFieldsOnly: false,
  referralFormFields: [],
}

export type AwsProducts = {
  ProductCode: string
  productName: string
  productDescription: string
  productFamily: string
}

export type PartnerCentralUsers = {
  email: string
  firstName: string
  lastName: string
  username: string
}

export type ApiResponseProduct = {
  aws_product_code: string
  product_name: string
  product_description: string
  product_family: string
}

export type CoSellAction =
  | {
      type: COSELL_ACTIONS.SET_REFERRALS
      payload: {
        referral: ReferralObject[]
        count: number
        pageSize: number
        pageNumber: number
        hasMore: boolean
      }
    }
  | {
      type: COSELL_ACTIONS.SET_REFERRALS_METRICS
      payload: ReferralMetrics
    }
  | {
      type: COSELL_ACTIONS.SET_AWS_PRODUCTS
      payload: SelectorOptions[]
    }
  | {
      type: COSELL_ACTIONS.SET_PARTNER_CENTRAL_USER_DETAILS
      payload: PartnerCentralUsers[]
    }
  | {
      type: COSELL_ACTIONS.SET_SOLUTION_OFFERED_OPTIONS
      payload: SelectorOptions[]
    }
  | {
      type: COSELL_ACTIONS.SET_REFERRAL_FORM_FIELDS
      payload: ReferralFormField[]
    }
  | {
      type: COSELL_ACTIONS.CLEAR_REFERRALS_DATA
    }
  | {
      type: COSELL_ACTIONS.SET_SHOW_ALL_FIELDS
      payload: { isShowRequiredFieldsOnly: boolean }
    }
  | {
      type: COSELL_ACTIONS.SET_CATEGORIES_WITH_NO_FIELDS
      payload: string[]
    }

export const reducer: Reducer<CoSellState, CoSellAction> = (
  state = initialState,
  action: CoSellAction
) => {
  switch (action.type) {
    case COSELL_ACTIONS.SET_REFERRALS: {
      const { referral, count, pageSize, pageNumber, hasMore } = action.payload
      return {
        ...state,
        referralObjects: {
          ...state.referralObjects,
          referrals: referral,
          count: count,
          pageNumber: pageNumber,
          pageSize: pageSize,
          hasMore: hasMore,
        },
      }
    }
    case COSELL_ACTIONS.SET_REFERRALS_METRICS: {
      return { ...state, referralMetrics: action.payload }
    }
    case COSELL_ACTIONS.SET_AWS_PRODUCTS: {
      return { ...state, awsProducts: action.payload }
    }
    case COSELL_ACTIONS.SET_PARTNER_CENTRAL_USER_DETAILS: {
      return { ...state, partnerCentralUsers: action.payload }
    }
    case COSELL_ACTIONS.SET_SOLUTION_OFFERED_OPTIONS: {
      return { ...state, solutionOfferedOptions: action.payload }
    }
    case COSELL_ACTIONS.CLEAR_REFERRALS_DATA: {
      return {
        ...state,
        referralObjects: null,
      }
    }
    case COSELL_ACTIONS.SET_SHOW_ALL_FIELDS: {
      return {
        ...state,
        isShowRequiredFieldsOnly: action.payload.isShowRequiredFieldsOnly,
      }
    }
    case COSELL_ACTIONS.SET_CATEGORIES_WITH_NO_FIELDS: {
      return { ...state, categoriesWithNoFields: action.payload }
    }
    case COSELL_ACTIONS.SET_REFERRAL_FORM_FIELDS: {
      return { ...state, referralFormFields: action.payload }
    }
    default:
      return state
  }
}

export type ReferralAPI = {
  Id: string
  Name: string
  Labra__APN_CRM_Unique_Identifier__c: string
  Labra__AWS_Account_Id__c: string
  Labra__AWS_Account_Owner_Email__c: string
  Labra__AWS_Account_Owner_Name__c: string
  Labra__AWS_Internal_Stage__c: string
  Labra__AWS_Partner_Central_User_Email__c: string
  Labra__AWS_Partner_Central_User_Name__c: string
  Labra__AWS_Sales_Rep_Email__c: string
  Labra__AWS_Sales_Rep_Name__c: string
  Labra__Acceptance_Status__c: string
  Labra__City__c: string
  Labra__Company_Name__c: string
  Labra__Country__c: string
  Labra__Customer_Email__c: string
  Labra__Customer_First_Name__c: string
  Labra__Customer_Last_Name__c: string
  Labra__Customer_Phone__c: string
  Labra__Customer_Title__c: string
  Labra__Delivery_Model__c: string
  Labra__Description__c: string
  Labra__Expected_Monthly_AWS_Revenue__c: number
  Labra__ISV_Manager_Email__c: string
  Labra__ISV_Manager_Name__c: string
  Labra__Industry__c: string
  Labra__IsOppFromMarketingActivity__c: string
  Labra__Next_Step_History__c: string
  Labra__Next_step__c: string
  Labra__Competitive_Tracking__c: string
  Labra__Other_Competitive_Tracking__c: string
  Labra__Other_Industry__c: string
  Labra__Other_PDM_Email__c: string
  Labra__Other_PDM__c: string
  Labra__PDM_Email__c: string
  Labra__PDM__c: string
  Labra__Postal_Code__c: string
  Labra__Primary_Need_From_AWS_MultiSelect__c: string
  Labra__Primary_Sales_Rep_Email__c: string
  Labra__Primary_Sales_Rep_First_Name__c: string
  Labra__Primary_Sales_Rep_Last_Name__c: string
  Labra__Primary_Sales_Rep_Phone__c: string
  Labra__Referred_By__c?: string
  Labra__Street_Address__c: string
  Labra__Success_Manager_Email__c: string
  Labra__Success_Manager_Name__c: string
  Labra__Sync_to_AWS__c: boolean
  Labra__Use_Case__c: string
  Labra__Website__c: string
  Labra__awsSFCampaignName__c: string | null
  Labra__State__c: string
  Labra__Labra_ACE_CRM_Lead_UniqueIdentifier__c: string
  Labra__apnReviewerComments__c: string
  Labra__Other_Solution_Offered__c: string
  Labra__Parent_Opportunity_ID__c: string
  Labra__Partner_Contact_Title__c: string
  Labra__AWS_Recommended_Action__c: string
  Labra__Offer_ID__c: string
  Labra__Target_Close_Date__c: string
  Labra__Solution_offered__c: string
  Labra__Opportunity_Type__c: string
  Labra__Sales_Activities__c: string
  Labra__Customer_DUNS__c: string
  Labra__Campaign_Name__c: string
  Labra__AWS_products__c: string
  Labra__Last_Updated_By__c: ReferralUpdatedBy
  Labra__Referral_Stage__c: string
  Labra__Rejection_reason__c: string
  Labra__AWS_Closed_Lost_Reason__c: string
  Labra__Has_classified_NatSec_Information__c: string
  Labra__AWS_Closed_Lost_Reason_Code__c: string
  Labra__Additional_Comments__c: string
  Labra__AWS_Close_Date__c: string
  Labra__contractStartDate__c: string
  Labra__contractEndDate__c: string
  Labra__customerSoftwareValueCurrency__c: string
  Labra__customerSoftwareValue__c: number | null
  Labra__procurementType__c: string
  Labra__marketingActivityChannel__c: string | null
  Labra__marketingActivityUsecase__c: string | null
  Labra__AWS_Marketplace_Engagement_Score__c: string
  Labra__ACE_Created_Date__c: string
  Labra__Created_Date__c: string
  Labra__ACE_Last_Modified_Date__c: string
  Labra__Labra_Offer__c: string
  Labra__MDF_Needed__c: string | null
  Labra__Customer_Segment__c?: string
  Labra__isGreenfield__c?: string
  // Fields specific to the Co-Sell Table
  Labra__Cloud_Provider_Approval_Status__c?: string
  Labra__Referral_Owner__c?: string
  Labra__Last_Updated__c?: string
  Labra__Last_Sync_Status__c?: string
  Labra__acceptBy__c?: string
  Labra__AWS_Acceptance_Status__c?: string
  Labra__AWS_Lead_Status__c?: string
}
